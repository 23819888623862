import { default as indexPYa01zaaCIMeta } from "/opt/buildhome/repo/apps/web-client/pages/[companySlug]/index.vue?macro=true";
import { default as _91serviceSlug_93pOmMMd68rjMeta } from "/opt/buildhome/repo/apps/web-client/pages/[companySlug]/service/[serviceSlug].vue?macro=true";
import { default as index8KWcZhp0TuMeta } from "/opt/buildhome/repo/apps/web-client/pages/index.vue?macro=true";
import { default as loginmkLrCnfohcMeta } from "/opt/buildhome/repo/apps/web-client/pages/login.vue?macro=true";
import { default as registerg6s1vnsOHcMeta } from "/opt/buildhome/repo/apps/web-client/pages/register.vue?macro=true";
import { default as accountH28FGP1tArMeta } from "/opt/buildhome/repo/apps/web-client/pages/settings/account.vue?macro=true";
export default [
  {
    name: indexPYa01zaaCIMeta?.name ?? "companySlug___de",
    path: indexPYa01zaaCIMeta?.path ?? "/de/:companySlug()",
    meta: indexPYa01zaaCIMeta || {},
    alias: indexPYa01zaaCIMeta?.alias || [],
    redirect: indexPYa01zaaCIMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/[companySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPYa01zaaCIMeta?.name ?? "companySlug___en",
    path: indexPYa01zaaCIMeta?.path ?? "/en/:companySlug()",
    meta: indexPYa01zaaCIMeta || {},
    alias: indexPYa01zaaCIMeta?.alias || [],
    redirect: indexPYa01zaaCIMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/[companySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPYa01zaaCIMeta?.name ?? "companySlug___fr",
    path: indexPYa01zaaCIMeta?.path ?? "/fr/:companySlug()",
    meta: indexPYa01zaaCIMeta || {},
    alias: indexPYa01zaaCIMeta?.alias || [],
    redirect: indexPYa01zaaCIMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/[companySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPYa01zaaCIMeta?.name ?? "companySlug___tr",
    path: indexPYa01zaaCIMeta?.path ?? "/tr/:companySlug()",
    meta: indexPYa01zaaCIMeta || {},
    alias: indexPYa01zaaCIMeta?.alias || [],
    redirect: indexPYa01zaaCIMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/[companySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPYa01zaaCIMeta?.name ?? "companySlug___it",
    path: indexPYa01zaaCIMeta?.path ?? "/it/:companySlug()",
    meta: indexPYa01zaaCIMeta || {},
    alias: indexPYa01zaaCIMeta?.alias || [],
    redirect: indexPYa01zaaCIMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/[companySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_93pOmMMd68rjMeta?.name ?? "companySlug-service-serviceSlug___de",
    path: _91serviceSlug_93pOmMMd68rjMeta?.path ?? "/de/:companySlug()/service/:serviceSlug()",
    meta: _91serviceSlug_93pOmMMd68rjMeta || {},
    alias: _91serviceSlug_93pOmMMd68rjMeta?.alias || [],
    redirect: _91serviceSlug_93pOmMMd68rjMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/[companySlug]/service/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_93pOmMMd68rjMeta?.name ?? "companySlug-service-serviceSlug___en",
    path: _91serviceSlug_93pOmMMd68rjMeta?.path ?? "/en/:companySlug()/service/:serviceSlug()",
    meta: _91serviceSlug_93pOmMMd68rjMeta || {},
    alias: _91serviceSlug_93pOmMMd68rjMeta?.alias || [],
    redirect: _91serviceSlug_93pOmMMd68rjMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/[companySlug]/service/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_93pOmMMd68rjMeta?.name ?? "companySlug-service-serviceSlug___fr",
    path: _91serviceSlug_93pOmMMd68rjMeta?.path ?? "/fr/:companySlug()/service/:serviceSlug()",
    meta: _91serviceSlug_93pOmMMd68rjMeta || {},
    alias: _91serviceSlug_93pOmMMd68rjMeta?.alias || [],
    redirect: _91serviceSlug_93pOmMMd68rjMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/[companySlug]/service/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_93pOmMMd68rjMeta?.name ?? "companySlug-service-serviceSlug___tr",
    path: _91serviceSlug_93pOmMMd68rjMeta?.path ?? "/tr/:companySlug()/service/:serviceSlug()",
    meta: _91serviceSlug_93pOmMMd68rjMeta || {},
    alias: _91serviceSlug_93pOmMMd68rjMeta?.alias || [],
    redirect: _91serviceSlug_93pOmMMd68rjMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/[companySlug]/service/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_93pOmMMd68rjMeta?.name ?? "companySlug-service-serviceSlug___it",
    path: _91serviceSlug_93pOmMMd68rjMeta?.path ?? "/it/:companySlug()/service/:serviceSlug()",
    meta: _91serviceSlug_93pOmMMd68rjMeta || {},
    alias: _91serviceSlug_93pOmMMd68rjMeta?.alias || [],
    redirect: _91serviceSlug_93pOmMMd68rjMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/[companySlug]/service/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: index8KWcZhp0TuMeta?.name ?? "index___de",
    path: index8KWcZhp0TuMeta?.path ?? "/de",
    meta: index8KWcZhp0TuMeta || {},
    alias: index8KWcZhp0TuMeta?.alias || [],
    redirect: index8KWcZhp0TuMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index8KWcZhp0TuMeta?.name ?? "index___en",
    path: index8KWcZhp0TuMeta?.path ?? "/en",
    meta: index8KWcZhp0TuMeta || {},
    alias: index8KWcZhp0TuMeta?.alias || [],
    redirect: index8KWcZhp0TuMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index8KWcZhp0TuMeta?.name ?? "index___fr",
    path: index8KWcZhp0TuMeta?.path ?? "/fr",
    meta: index8KWcZhp0TuMeta || {},
    alias: index8KWcZhp0TuMeta?.alias || [],
    redirect: index8KWcZhp0TuMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index8KWcZhp0TuMeta?.name ?? "index___tr",
    path: index8KWcZhp0TuMeta?.path ?? "/tr",
    meta: index8KWcZhp0TuMeta || {},
    alias: index8KWcZhp0TuMeta?.alias || [],
    redirect: index8KWcZhp0TuMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index8KWcZhp0TuMeta?.name ?? "index___it",
    path: index8KWcZhp0TuMeta?.path ?? "/it",
    meta: index8KWcZhp0TuMeta || {},
    alias: index8KWcZhp0TuMeta?.alias || [],
    redirect: index8KWcZhp0TuMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginmkLrCnfohcMeta?.name ?? "login___de",
    path: loginmkLrCnfohcMeta?.path ?? "/de/login",
    meta: loginmkLrCnfohcMeta || {},
    alias: loginmkLrCnfohcMeta?.alias || [],
    redirect: loginmkLrCnfohcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginmkLrCnfohcMeta?.name ?? "login___en",
    path: loginmkLrCnfohcMeta?.path ?? "/en/login",
    meta: loginmkLrCnfohcMeta || {},
    alias: loginmkLrCnfohcMeta?.alias || [],
    redirect: loginmkLrCnfohcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginmkLrCnfohcMeta?.name ?? "login___fr",
    path: loginmkLrCnfohcMeta?.path ?? "/fr/login",
    meta: loginmkLrCnfohcMeta || {},
    alias: loginmkLrCnfohcMeta?.alias || [],
    redirect: loginmkLrCnfohcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginmkLrCnfohcMeta?.name ?? "login___tr",
    path: loginmkLrCnfohcMeta?.path ?? "/tr/login",
    meta: loginmkLrCnfohcMeta || {},
    alias: loginmkLrCnfohcMeta?.alias || [],
    redirect: loginmkLrCnfohcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginmkLrCnfohcMeta?.name ?? "login___it",
    path: loginmkLrCnfohcMeta?.path ?? "/it/login",
    meta: loginmkLrCnfohcMeta || {},
    alias: loginmkLrCnfohcMeta?.alias || [],
    redirect: loginmkLrCnfohcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registerg6s1vnsOHcMeta?.name ?? "register___de",
    path: registerg6s1vnsOHcMeta?.path ?? "/de/register",
    meta: registerg6s1vnsOHcMeta || {},
    alias: registerg6s1vnsOHcMeta?.alias || [],
    redirect: registerg6s1vnsOHcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerg6s1vnsOHcMeta?.name ?? "register___en",
    path: registerg6s1vnsOHcMeta?.path ?? "/en/register",
    meta: registerg6s1vnsOHcMeta || {},
    alias: registerg6s1vnsOHcMeta?.alias || [],
    redirect: registerg6s1vnsOHcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerg6s1vnsOHcMeta?.name ?? "register___fr",
    path: registerg6s1vnsOHcMeta?.path ?? "/fr/register",
    meta: registerg6s1vnsOHcMeta || {},
    alias: registerg6s1vnsOHcMeta?.alias || [],
    redirect: registerg6s1vnsOHcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerg6s1vnsOHcMeta?.name ?? "register___tr",
    path: registerg6s1vnsOHcMeta?.path ?? "/tr/register",
    meta: registerg6s1vnsOHcMeta || {},
    alias: registerg6s1vnsOHcMeta?.alias || [],
    redirect: registerg6s1vnsOHcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerg6s1vnsOHcMeta?.name ?? "register___it",
    path: registerg6s1vnsOHcMeta?.path ?? "/it/register",
    meta: registerg6s1vnsOHcMeta || {},
    alias: registerg6s1vnsOHcMeta?.alias || [],
    redirect: registerg6s1vnsOHcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: accountH28FGP1tArMeta?.name ?? "settings-account___de",
    path: accountH28FGP1tArMeta?.path ?? "/de/settings/account",
    meta: accountH28FGP1tArMeta || {},
    alias: accountH28FGP1tArMeta?.alias || [],
    redirect: accountH28FGP1tArMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: accountH28FGP1tArMeta?.name ?? "settings-account___en",
    path: accountH28FGP1tArMeta?.path ?? "/en/settings/account",
    meta: accountH28FGP1tArMeta || {},
    alias: accountH28FGP1tArMeta?.alias || [],
    redirect: accountH28FGP1tArMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: accountH28FGP1tArMeta?.name ?? "settings-account___fr",
    path: accountH28FGP1tArMeta?.path ?? "/fr/settings/account",
    meta: accountH28FGP1tArMeta || {},
    alias: accountH28FGP1tArMeta?.alias || [],
    redirect: accountH28FGP1tArMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: accountH28FGP1tArMeta?.name ?? "settings-account___tr",
    path: accountH28FGP1tArMeta?.path ?? "/tr/settings/account",
    meta: accountH28FGP1tArMeta || {},
    alias: accountH28FGP1tArMeta?.alias || [],
    redirect: accountH28FGP1tArMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: accountH28FGP1tArMeta?.name ?? "settings-account___it",
    path: accountH28FGP1tArMeta?.path ?? "/it/settings/account",
    meta: accountH28FGP1tArMeta || {},
    alias: accountH28FGP1tArMeta?.alias || [],
    redirect: accountH28FGP1tArMeta?.redirect,
    component: () => import("/opt/buildhome/repo/apps/web-client/pages/settings/account.vue").then(m => m.default || m)
  }
]