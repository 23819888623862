<template>
  <NuxtLayout>
    <NuxtPage/>
  </NuxtLayout>
</template>

<script setup>

const sessionId = useCookie("sessionId")
const idGenerator = useIdGenerator()

if (sessionId.value == undefined) {
  sessionId.value = idGenerator.generate()
}

onMounted(() => {
  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }
  window.addEventListener('resize', appHeight)
  appHeight()
})
</script>
