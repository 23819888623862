import revive_payload_client_YTGlmQWWT5 from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/apps/web-client/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_g8ddcgr0n7 from "/opt/buildhome/repo/node_modules/nuxt-mapbox/dist/runtime/plugin.client.mjs";
import script_UKdghfPT0q from "/opt/buildhome/repo/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import i18n_dOTnCc6TUQ from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import supabase_client_qb1L118OuS from "/opt/buildhome/repo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import view_transitions_client_HXqymDRHmq from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import telInput_gKdE80rqC2 from "/opt/buildhome/repo/apps/web-client/plugins/telInput.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_client_g8ddcgr0n7,
  script_UKdghfPT0q,
  i18n_dOTnCc6TUQ,
  supabase_client_qb1L118OuS,
  view_transitions_client_HXqymDRHmq,
  chunk_reload_client_SeG0EjL5Ec,
  telInput_gKdE80rqC2
]